import React from 'react';
import axios from 'axios';
import './Login.css';

function Register() {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [message, setMessage] = React.useState('');

    function handleSubmit(e) {
        e.preventDefault();
        axios.post('http://192.168.1.15:3002/register', { email, password })
            .then(res => setMessage(res.data))
            .catch(err => setMessage('Registration failed'));
    }

    return (
        <div className="wrapper">
            <form onSubmit={handleSubmit}>
                <h1>Register</h1>
                <div className="input-box">
                    <input 
                        type="email" 
                        placeholder="Email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                    />
                    <i className='bx bx-user'></i>
                </div>
                <div className="input-box">
                    <input 
                        type="password" 
                        placeholder="Password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required 
                    />
                    <i className='bx bx-lock'></i>
                </div>

                <button type="submit" className="btn">Register</button>

                {message && <div className={`message ${message === 'Registration successful' ? 'text-success' : 'text-danger'}`}>{message}</div>}

                <div className="login-link">
                    <p>Already have an account? <a href="#">Login Here</a></p>
                </div>
            </form>
        </div>
    );
}

export default Register;
