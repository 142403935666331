import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Dashboard() {
    const [username, setUsername] = useState('');

    useEffect(() => {
        axios.get('http://localhost:3001/user', { withCredentials: true })
            .then(res => {
                setUsername(res.data.username);
            })
            .catch(err => {
                console.error(err);
                setUsername('Guest');
            });
    }, []);

    return (
        <div className="dashboard">
            <h1>Hallo {username}</h1>
        </div>
    );
}

export default Dashboard;