import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login() {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [message, setMessage] = React.useState('');
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();
        axios.post('http://192.168.1.15:3002/login', { email, password }, { withCredentials: true })
            .then(res => {
                setMessage(res.data.message);
                if (res.data.redirect) {
                    navigate(res.data.redirect);
                }
            })
            .catch(err => setMessage('Login failed'));
    }

    return (
        <div className="wrapper">
            <form onSubmit={handleSubmit}>
                <h1>Login</h1>
                <div className="input-box">
                    <input 
                        type="email" 
                        placeholder="Email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                    />
                    <i className='bx bx-user'></i>
                </div>
                <div className="input-box">
                    <input 
                        type="password" 
                        placeholder="Password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required 
                    />
                    <i className='bx bx-lock'></i>
                </div>

                <div className="remember-forgot">
                    <label>
                        <input type="checkbox" /> Remember Me
                    </label>
                    <a href="#">Forgot Password</a>
                </div>

                <button type="submit" className="btn">Login</button>

                {message && <div className={`message ${message === 'Login successful' ? 'text-success' : 'text-danger'}`}>{message}</div>}

                <div className="register-link">
                    <p>Don't have an account? <a href="#">Register Here</a></p>
                </div>
            </form>
        </div>
    );
}

export default Login;
